import React from 'react';
import './styles/main.scss'
import HeaderImg from '../src/styles/images/b9baddaffd411fc5ec4d42584f9fbc24.jpeg'
import { Testimonials } from "./components/testimonials/Testimonials";

function App() {
  return (
    <div className="App">
      <header id='main' className="header">
        <div className="header__top">
          <div className="header__container">

            <a href="#main" className="header__logo">Genealogy in Ukraine</a>

            <nav className="header__nav nav">
              <ul className="nav__items">
                <a href="#main" className="nav__link">
                  <li className="nav__list">Main</li>
                </a>

                <a href="#aboutUs" className="nav__link">
                  <li className="nav__list">About us</li>
                </a>

                <a href="#services" className="nav__link">
                  <li className="nav__list">Services</li>
                </a>

                <a href="#price" className="nav__link">
                  <li className="nav__list">Price</li>
                </a>

                <a href="#testimonials" className="nav__link">
                  <li className="nav__list">Testimonials</li>
                </a>
              </ul>
            </nav>
          </div>
        </div>

        <img
            src={HeaderImg}
            alt="header__bg"
            className="header__bg"
        />
      </header>

      <main className="main">
        <section
            id="aboutUs"
            className="main__about-us about-us"
        >
          <div className="about-us__strip"></div>

          <h1 className="about-us__title">About us</h1>

          <p className="about-us__p">
            We are an experienced team of researchers, who have access to numerous genealogical sources, including the deepest Ukrainian, Polish, Russian and Hungarian archives. With the collaborative effort of our experts, you will discover your ancestors. Are you a descendant of a Cossack? Was your great-great-grandfather a prominent Hetman? Choose our service, and we will open the secrets of your bloodline.
            <br/>
            <br/>
            The Ukrainian nation was always fighting for their liberty, better standards of living. However, the destructive neighbor made people leave our country with its high potential. The range of our diaspora includes such countries as Canada, Poland, Spain, France, the US, Germany, Argentina, etc. Therefore, we offer to investigate your relation to our kin.
            <br/>
            <br/>
            How can you know without digging deeper into your genealogical table? We will trace your past for you, using modern tools and techniques. Moreover, our team of professionals is a thorough one. They find for you any required information about any distant relation. We have well-qualified genealogists, historians, and investigators.
          </p>
        </section>

        <section className="main__offers offers" id="offers">
          <h1 className="offers__title">What do we offer?</h1>

          <div className="offers__strip"></div>

          <div className="offers__mini-block">
            <div className="offers__items-1">
              <div className="offers__icon-1"></div>

              <p className="offers__text">
                Certified professional historians, genealogists
              </p>
            </div>

            <div className="offers__items-2">
              <div className="offers__icon-2"></div>

              <p className="offers__text">
                Experience and knowledge of different search algorithms for document detection.
              </p>
            </div>

            <div className="offers__items-3">
              <div className="offers__icon-3"></div>

              <p className="offers__text">
                A wide range of genealogical tools
              </p>
            </div>

            <div className="offers__items-4">
              <div className="offers__icon-4"></div>

              <p className="offers__text">
                A team of people who quickly provide quality archival work in various settlements of Ukraine and Eastern
                Poland
              </p>
            </div>

            <div className="offers__items-5">
              <div className="offers__icon-5"></div>

              <p className="offers__text">
                Access to historical records stored in Ukraine and Poland.
              </p>
            </div>

            <div className="offers__items-6">
              <div className="offers__icon-6"></div>

              <p className="offers__text">Turnkey project<br/>Minimize your time and resources.</p>
            </div>
          </div>
        </section>

        <section id="services" className="main__services services">

          <div className="services__title-block">
            <div className="services__rectangle0">
              <div className="services__rectangle01"></div>
            </div>

            <h1 className="services__title">
              Services
            </h1>

            <div className="services__rectangle1">
              <div className="services__rectangle2"></div>
            </div>
          </div>

          <div className="services__proffer proffer">
            <div className="proffer__left">
              <h2 className="proffer__title">
                Archival genealogical research
              </h2>

              <div className="proffer__icon-block">
                <div className="proffer__icon-rectangle"></div>
                <div className="proffer__icon"></div>
                <div className="proffer__icon-rectangle"></div>
              </div>

              <div className="proffer__block-text">
                <div className="proffer__block-icon">
                  <div className="proffer__block-icon-text"></div>
                  <p className="proffer__block-p">
                    Search and detection of archives documents and historical records
                  </p>
                </div>

                <div className="proffer__block-icon">
                  <div className="proffer__block-icon-text"></div>
                  <p className="proffer__block-p">
                    Direct family branch or extended family tree from up to 12 generations
                  </p>
                </div>

                <div className="proffer__block-icon">
                  <div className="proffer__block-icon-text"></div>
                  <p className="proffer__block-p">
                    Working with catalogs of historical records
                  </p>
                </div>

                <div className="proffer__block-icon">
                  <div className="proffer__block-icon-text"></div>
                  <p className="proffer__block-p">
                    Military documents
                  </p>
                </div>

                <div className="proffer__block-icon">
                  <div className="proffer__block-icon-text"></div>
                  <p className="proffer__block-p">
                    Documents on ownership of real estate
                  </p>
                </div>
              </div>

              <a href="" className="proffer__button">Read more</a>
            </div>

            <div className="proffer__rigth">
              <h2 className="proffer__title1">
                Genealogical expedition
              </h2>

              <div className="proffer__icon-block">
                <div className="proffer__icon-rectangle"></div>
                <div className="proffer__icon"></div>
                <div className="proffer__icon-rectangle"></div>
              </div>

              <div className="proffer__block-text">
                <div className="proffer__block-icon">
                  <div className="proffer__block-icon-text"></div>
                  <p className="proffer__block-p">
                    Visiting the places of your ancestors
                  </p>
                </div>

                <div className="proffer__block-icon">
                  <div className="proffer__block-icon-text"></div>
                  <p className="proffer__block-p">
                    Search for ancestral home
                  </p>
                </div>

                <div className="proffer__block-icon">
                  <div className="proffer__block-icon-text"></div>
                  <p className="proffer__block-p">
                    Research of cemeteries
                  </p>
                </div>

                <div className="proffer__block-icon">
                  <div className="proffer__block-icon-text"></div>
                  <p className="proffer__block-p">
                    Search for living relatives
                  </p>
                </div>
              </div>

              <a href="" className="proffer__button">Read more</a>
            </div>
          </div>
        </section>

        <section className="main__cost cost">
          <div className="services__title-block">
            <div className="services__rectangle3">
              <div className="services__rectangle04"></div>
            </div>

            <h1 className="services__title">
              Cost of services
            </h1>

            <div className="services__rectangle5">
              <div className="services__rectangle6"></div>
            </div>
          </div>

          <p className="cost-id" id="price"></p>

          <div className="cost__request">
            <h2 className="cost__request-title">Individual request</h2>

            <p className="cost__request-text">
              Please describe in more detail all the information
              <br/>and what exactly you want to explore!
            </p>

            <form
                action="https://formspree.io/f/xbjqzbwo"
                method="post"
                className="cost__request-form form"
            >
              <input
                  type="text"
                  name="name"
                  className="form__name"
                  placeholder="Enter your name please"
                  required
              >
              </input>

              <input
                  type="email" name="email"
                  className="form__mail"
                  placeholder="Enter your e-mail please"
                  required
              >
              </input>

              <textarea
                  rows="12"
                  name="message"
                  className="form__textarea"
                  placeholder="Enter your text please"
                  required
              ></textarea>

              <button className="form__button" type="submit">
                Get a calculation
              </button>
            </form>
          </div>
        </section>

        <div className="tree"></div>

        <section className="main__testimonials testimonials" id="testimonials">
          <Testimonials />
        </section>

      </main>
    </div>
  );
}

export default App;
