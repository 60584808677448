import DustinOlynyk from '../../styles/images/testimonials/DustinOlynyk.jpeg'
import EvabrittSundin from '../../styles/images/testimonials/EvabrittSundin.jpg'
import LeonardMariash from '../../styles/images/testimonials/LeonardMariash.jpg'
import KeithKoshute from '../../styles/images/testimonials/KeithKoshute.jpg'

export const testimonialsList = [
    {
        name: 'Dustin Olynyk',
        from: 'Lloydminster, CANADA',
        text: `Vitalii did the research on my Ancestors the Olijnyk family pre 1907. I highly recommend his services. He is very professional, thorough, and reasonably priced. He kept in contact with me throughout the process with updates and progress. He discovered many more family members and sent me parish records of births, baptisms, marriages, and voters list from their home village. I was so impressed with his research and findings that I recently requested a second round of research in other records. 5 star review. Thank you Mr. Kliukin!`,
        imgSrc: DustinOlynyk
    },
    {
        name: 'Evabritt Sundin',
        from: 'Norrköping, SWEDEN',
        text: `Yes, I did hire Andriy to find records in Transcarpathia. I gave him villages, family names and time period to search. He did find a record. He was upfront and honest. There are a lot of missing records in Transcarpathia. I was happy with his service.`,
        imgSrc: EvabrittSundin
    },
    {
        name: 'Leonard Mariash',
        from: 'from Facebook',
        text: `The information that you found is excellent. I now have to redo my family tree but that is life. It was a pleasure working with you. Thank you so very much. I'll contact you again when I need more information. At age 78, I doubt that I will go to Ukraine again.`,
        imgSrc: LeonardMariash
    },
    {
        name: 'Keith Koshute',
        from: 'Palmyra, USA',
        text: `TI was happy with what you researched. Have some questions and planned to ask you to do more. Yes, the virus has everyone house bound and distracted. I'm sorry some are upset. You did get by me!`,
        imgSrc: KeithKoshute
    },
]

export const owlCarouselResponsiveData = {
    0: {
        items: 1,
        nav: true,
    },
    700: {
        items: 2,
        nav: true,
    },
    1000: {
        items: 3,
        nav: true,
    }
}
