import React from 'react';
import {
    testimonialsList,
    owlCarouselResponsiveData,
} from "./helper";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

export const Testimonials = () => {
  return (
      <>
          <div className="testimonials__title-block title-block">
              <div className="title-block__rectangle1"></div>

              <h2 className="title-block__title">
                  Testimonials
              </h2>

              <div className="title-block__rectangle1"></div>
          </div>

          <div className="carousel">
              <div className="carousel__container">
                  <OwlCarousel
                      nav
                      loop
                      margin={10}
                      className={'owl-theme'}
                      responsive={owlCarouselResponsiveData}
                  >
                      {
                          testimonialsList.map((element, index) =>
                              <div className="testimonials__items item">
                                  <div className="items__testimonial testimonial">
                                      <img
                                          src={element.imgSrc}
                                          className="testimonial__img"
                                          id="testimonial__img"
                                      />

                                      <div className="testimonial__text">
                                          <h3 className="testimonial__name">{element.name}</h3>

                                          <p className="testimonial__from">{element.from}</p>
                                      </div>
                                  </div>

                                  <div className="items__text">
                                      <h3 className="items__quotes">"</h3>

                                      <i className="items__paragraph">{element.text}</i>

                                      <h3 className="items__quotes">"</h3>
                                  </div>
                              </div>
                          )
                      }
                  </OwlCarousel>
              </div>
          </div>
      </>
  )
}
